'use strict';
import { Injectable, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Injectable()
export class PermissionService implements OnInit {
    url: string;
    permissions: any;
    displaySidebar = [];
    hidden = false;
    items: any;
    displayAllNav = [];
    displayTrialTools = [];
    ristrictedUrl = [];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService
    ) {
        this.activatedRoute.url.subscribe(activeUrl => {
            this.url = window.location.pathname;
        });
    }

    ngOnInit() { }

    showHideMenuItem(items): void {
        this.items = items;
        /** Get all navigations */
        let getMainNavigation = this._fuseNavigationService.getNavigation('main');
        let newItems = []
        if (this.items.length >= 1) {
            this.items.map(singleItems => {
                if (singleItems === "Sponsor Logo") {
                    singleItems = "sponser-management"
                }
                if (singleItems === "Categories & Contents") {
                    singleItems = "categories-management"
                }
                if (singleItems === "My Patients") {
                    singleItems = "patients"
                }
                if (singleItems === "CMS") {
                    singleItems = "cms"
                }
                if (singleItems === "Trial Tool - Studies") {
                    singleItems = "studies"
                }
                if (singleItems === "Trial Tool - Countries") {
                    singleItems = "countries"
                }
                if (singleItems === "Trial Tool - Disease") {
                    singleItems = "disease"
                }
                if (singleItems === "Trial Tool - Study Status") {
                    singleItems = "study-status"
                }
                if (singleItems === "Trial Tool - Principal Investigator") {
                    singleItems = "principal-investigator"
                }
                if (singleItems === "Trial Tool - Line Of Treatment") {
                    singleItems = "line-of-treatment"
                }
                if (singleItems === "Trial Tool - Hospital") {
                    singleItems = "hospital"
                }

                /** Configuration if no permissions but try to send request without permissions page display error page  */
                getMainNavigation.map(singleNavigation => {
                    if (singleNavigation.title === 'Trial Tool') {
                        singleNavigation.children.map(singleToolItem => {
                            if (singleItems == singleToolItem.id) {
                                if (singleToolItem.url === this.url) {
                                    this.router.navigate(['errors/error-404']);
                                }
                            }
                        })
                    } else {
                        if (singleItems == singleNavigation.id) {
                            if (singleNavigation.url === this.url) {
                                this.router.navigate(['errors/error-404']);
                            }
                        }
                    }
                })

                /** Function for hide navigations if all permissions of module restricted */
                this._fuseNavigationService.updateNavigationItem(singleItems, {
                    hidden: true
                });
                newItems.push(singleItems)

            })

            /** Function for Show navigations if at lease one permission in module */
            let test = this._fuseNavigationService
            let visiblePermission = getMainNavigation.filter(function (el) {
                if (el.id === "trial-tool") {
                    el.children.filter((children) => {
                        if (newItems.indexOf(children.id) < 0) {
                            test.updateNavigationItem(children.id, {
                                hidden: false
                            });
                            // return el
                        };
                    })
                } else {
                    if (newItems.indexOf(el.id) < 0) {
                        test.updateNavigationItem(el.id, {
                            hidden: false
                        });
                        // return el
                    };
                }
            });
        } else {
            /** If no any module restricted view all navigations */
            let getData = this._fuseNavigationService.getNavigation('main');
            getData.map(displayNav => {
                if (displayNav.id === "trial-tool") {
                    displayNav.children.map(singleTrialToolChildren => {
                        if (this.displayTrialTools.indexOf(singleTrialToolChildren.id) === -1) {
                            this.displayTrialTools.push(singleTrialToolChildren.id);
                        }
                    })
                }
                if (this.displayAllNav.indexOf(displayNav.id) === -1) {
                    this.displayAllNav.push(displayNav.id);
                }
            })
            this.displayAllNav.map(singleNavItem => {
                this._fuseNavigationService.updateNavigationItem(singleNavItem, {
                    hidden: false
                });
            })
            this.displayTrialTools.map(singleTrialToolNavItem => {
                this._fuseNavigationService.updateNavigationItem(singleTrialToolNavItem, {
                    hidden: false
                });
            })
        }
    }
}