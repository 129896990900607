import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../common/adminService';
import { MatAccordion } from '@angular/material';

@Component({
  selector: 'app-view-accordion',
  templateUrl: './view-accordion.component.html',
  styleUrls: ['./view-accordion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ViewAccordionComponent implements OnInit {
  id: string;
  getContentData: any;
  noData = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  /** For ExpandAll & CollapseAll */
  panelOpenState: boolean = false;
  allExpandState = false;
  contentType: any;
  index: any;
  openIndex: any;
  closeIndex: any;
  isIOSDevice: boolean = false;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private router: Router
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    var classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
      this.isIOSDevice = true;
    }

    if (navigator.userAgent.match(/android/i)) {
      this.isIOSDevice = false;
    }

    this.adminService.getContentForUser(this.id).subscribe((content) => {


      this.getContentData = content['data'].content;
      this.contentType = content['data'].sContentType
      if (this.contentType !== 'aCollapse_table') {
        this.noData = true;
        // this.router.navigate(['errors/error-404']);
      }
    }, error => {
      this.router.navigate(['errors/error-404']);
    });
  }

  openState(i) {
    this.openIndex = i;
    this.panelOpenState = true
  }

  closeState(i) {
    this.closeIndex = i;
    this.panelOpenState = false
  }

}

