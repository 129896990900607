import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewAccordionComponent } from './view-accordion.component';

const childRoutes: Routes = [
    {
        path: 'accordion/view/:id',
        component: ViewAccordionComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(childRoutes)],
    exports: [RouterModule],
})
export class ViewAccordionRoutingModule { }
