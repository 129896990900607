import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ViewTextRoutingModule } from './view-text-routing.module';
import { ViewTextComponent } from './view-text.component';

@NgModule({
    declarations: [
        ViewTextComponent
    ],
    imports: [
        ViewTextRoutingModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
    ],
    // entryComponents: [ViewTextComponent]
})
export class ViewTextModule {
}
