import { NgModule } from '@angular/core';
import {
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ChangePasswordComponent } from 'app/main/admin/change-password/change-password.component';
import { ChangePasswordRoutingModule } from './change-password-routing.module';

@NgModule({
    declarations: [
        ChangePasswordComponent
    ],
    imports: [
        ChangePasswordRoutingModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        FuseSharedModule,
        MatProgressSpinnerModule
    ]
})
export class ChangePasswordModule { }