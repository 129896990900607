import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import {AdminService  } from "./../../common/adminService";
import { SnackBarService } from 'app/main/common/snackbar.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: fuseAnimations,

})
export class SearchComponent implements OnInit {
  public data:any
  public sSearch: String;
  loading: boolean = false;
  constructor(
    private adminService: AdminService,
    private snackBarService: SnackBarService,
  ) { }

  ngOnInit() {
    this.adminService.viewSetting().subscribe((data)=>{
      
      this.data=data
    })
  }

  onChangeCriteria(value){
    this.sSearch = value.value;
  }

  saveCriteria() {
    // 
  this.adminService.updateSetting({sSearchBy:this.sSearch}).subscribe((response)=>{
    if(response){
      this.snackBarService.snackbarMesssage(response['message'], 'Cancel');
    }
    
  }),(error) => {
    this.loading = true;
    if (error.error.status == 401) {
      let message = error.error.message;
      this.snackBarService.snackbarMesssage(message, 'Cancel');
    } else {
      let message = error.error.message;
      this.snackBarService.snackbarMesssage(message, 'Cancel');
    }
  }
  }

}
