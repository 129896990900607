import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { MailConfirmComponent } from 'app/main/mail-confirm/mail-confirm.component';
import { MailConfirmRoutingModule } from './mail-confirm-routing.module';

@NgModule({
    declarations: [
        MailConfirmComponent
    ],
    imports: [
        MailConfirmRoutingModule,
        MatIconModule,
        FuseSharedModule
    ]
})
export class MailConfirmModule {
}
