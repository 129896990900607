import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router, NavigationExtras } from '@angular/router';
import { first } from 'rxjs/operators';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { AdminService } from '../common/adminService';

@Component({
	selector: 'forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: FormGroup;
	loading: boolean = false;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: FormBuilder,
		private adminService: AdminService,
		private router: Router,
		private snackBarService: SnackBarService
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	ngOnInit(): void {
		this.forgotPasswordForm = this._formBuilder.group({
			sEmail: ['', [Validators.required, Validators.email]]
		});
	}

	forgottenpassword() {
		this.loading = true
		this.adminService.forgotPassword(this.forgotPasswordForm.value).pipe(first()).subscribe(
			(success) => {
				this.loading = false;
				this.emailFetch(success.data.sEmail);
			},
			(error) => {
				this.loading = false;
				this.snackBarService.snackbarMesssage(error.message, 'Cancel');
			}
		);
	}

	/** send email in route */
	emailFetch(email) {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				Femail: email
			},
			skipLocationChange: true
		};
		this.router.navigate(['/mail-confirm'], navigationExtras);
	}
}
