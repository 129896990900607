import { NgModule } from '@angular/core';
import { MatButtonModule, MatDialogModule, MatIconModule, MatProgressSpinnerModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
	declarations: [
		FuseConfirmDialogComponent
	],	
	imports: [
		FuseSharedModule,
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		CommonModule,
		MatProgressSpinnerModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		MatSelectModule
	],
	entryComponents: [FuseConfirmDialogComponent]
})
export class FuseConfirmDialogModule { }
