import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AdminService } from 'app/main/common/adminService';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

@Component({
	selector: 'profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class ProfileComponent implements OnInit {
	profileForm: any;
	requireImage: string;
	formatInvailid: string;
	sizeInvailid: string;
	profilePicUrl: any;
	inputElActive: any;
	response: any;
	phone = '^[0-9]{6,15}$';
	loading: boolean = false;
	loadingpage: boolean = true;

	constructor(
		private adminService: AdminService,
		private router: Router,
		private snackBarService: SnackBarService,
		private _formBuilder: FormBuilder,
		private el: ElementRef
	) { }

	ngOnInit() {
		this.loadingpage = true;
		this.adminService.getprofiledata().subscribe((res) => {
			this.loadingpage = false;
			this.response = res.data;
			if (res.data.sProfilePicture) {
				this.profilePicUrl = environment.profileUrl + this.response.sProfilePicture;
			} else {
				res.data.sProfilePicture = "assets/images/avatars/profile.jpg"
			}
			this.profileForm = this._formBuilder.group({
				sFirstName: [this.response.sFirstName, [Validators.required]],
				sLastName: [this.response.sLastName, [Validators.required]],
				sEmail: [this.response.sEmail],
				sPhoneNumber: [
					this.response.sPhoneNumber,
					Validators.compose([Validators.pattern(this.phone)])
				]
			});
		}, error => {
			this.loadingpage = false;
			this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
		});
	}

	//image upload
	readUrl(event) {
		if (event) {
			this.requireImage = '';
			this.formatInvailid = '';
			this.sizeInvailid = '';

			if (event.target.files && event.target.files[0]) {
				if (event.target.files && event.target.files[0].size < 1017241) {
					var fileExt = event.target.files[0].name.split('.').pop();
					var allowedExtensions = environment.allowedImages;
					if (allowedExtensions.indexOf(fileExt) > -1) {
						var reader = new FileReader();
						reader.onload = (event) => {
							this.profilePicUrl = event.target['result'];
						};
						reader.readAsDataURL(event.target.files[0]);
						this.inputElActive = HTMLInputElement = this.el.nativeElement
							.querySelector('#file')
							.files.item(0);

						//profile picture api call
						let formData = new FormData();
						formData.append('sProfilePicture', this.inputElActive);
						this.adminService.updateprofilepic(formData).subscribe(
							(user) => {
								this.snackBarService.snackbarMesssage(user['message'], 'Cancel');
								this.adminService.getprofiledata().subscribe((data) => { });
							},
							(error) => {
								this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
							}
						);
					} else {
						this.inputElActive = this.profilePicUrl;
						this.snackBarService.snackbarMesssage(
							'Profile picture must be in PNG,JPG or JPEG format',
							'Cancel'
						);
					}
				} else {
					this.snackBarService.snackbarMesssage('Image picture size must be less than 1 MB', 'Cancel');
				}
			}
		}
	}

	editProfile() {
		this.loading = true;
		this.adminService.updateAdminProfile(this.profileForm.value).subscribe(
			(user) => {
				this.loading = false;
				this.snackBarService.snackbarMesssage(user['message'], 'Cancel');
				this.router.navigate(['/dashboard']);
			},
			(error) => {
				this.loading = false;
				this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
			}
		);
	}
}
