import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EducationalwebviewRoutingModule } from './educationalwebview-routing.module';
import { EducationalwebviewComponent } from './educationalwebview.component';
import { MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatExpansionModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';

@NgModule({
  declarations: [EducationalwebviewComponent],
  imports: [
    CommonModule,
    EducationalwebviewRoutingModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule
  ]
})
export class EducationalwebviewModule { }
