import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewTextComponent } from './view-text.component';

const childRoutes: Routes = [
    {
        path: 'text/view/:id',
        component: ViewTextComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(childRoutes)],
    exports: [RouterModule],
})
export class ViewTextRoutingModule { }
