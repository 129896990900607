import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatExpansionModule } from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { ViewAccordionRoutingModule } from './view-accordion-routing.module';
import { ViewAccordionComponent } from './view-accordion.component';

@NgModule({
    declarations: [
        ViewAccordionComponent
    ],
    imports: [
        ViewAccordionRoutingModule,
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        MatExpansionModule
    ]
})
export class ViewAccordionModule {}
