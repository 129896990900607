
"use strict";
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AdminService } from './adminService';


@Injectable()
export class RoutingGuard implements CanActivate {
    constructor(
        private adminService: AdminService
    ) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.adminService.isLoggedIn(state.url);
    }
}