import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService } from 'app/main/common/adminService';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  password = '.{8,}';
  loading :boolean = false;

    // Private
    private _unsubscribeAll: Subject<any>;
    usertype: string;
  

    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _formBuilder: FormBuilder,
        private adminService: AdminService,
        private snackBarService: SnackBarService,
        private router: Router
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void
    {   
        this.usertype = localStorage.getItem('UserType');     
        this.changePasswordForm = this._formBuilder.group({
            sOldPassword : ['',[Validators.required]],
            sNewPassword       : ['', Validators.compose([Validators.required,Validators.pattern(this.password)])],
            sNewRetypedPassword: ['', [Validators.required, confirmPasswordValidator]]
        });

        // Update the validity of the 'confirm_password' field
        // when the 'password' field changes
        this.changePasswordForm.get('sNewPassword').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.changePasswordForm.get('sNewRetypedPassword').updateValueAndValidity();
            });
    }

    /** Change Password function */
    changePassword() {
        this.loading = true;
        if(this.usertype == 'admin'){
		this.adminService
			.changePassword(this.changePasswordForm.value)
			.subscribe(
				(response) => {
                    this.loading = false
					let message = response.message;
					this.snackBarService.snackbarMesssage(message, 'Cancel');
					this.router.navigate([ '/' ]);
				},
				(error) => {
                    this.loading = false
                    this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
				}
            );
        } else {
            this.adminService
			.changepasswordinvestigator(this.changePasswordForm.value)
			.subscribe(
				(response) => {
                    this.loading = false
					let message = response.message;
					this.snackBarService.snackbarMesssage(message, 'Cancel');
					this.router.navigate([ '/' ]);
				},
				(error) => {
                    this.loading = false
                    this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
				}
            );
        }
	}


    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if ( !control.parent || !control )
  {
      return null;
  }

  const password = control.parent.get('sNewPassword');
  const confirm_password = control.parent.get('sNewRetypedPassword');

  if ( !password || !confirm_password )
  {
      return null;
  }

  if ( confirm_password.value === '' )
  {
      return null;
  }

  if ( password.value === confirm_password.value )
  {
      return null;
  }

  return {'passwordsNotMatching': true};
};