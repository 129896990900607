import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { AdminService } from '../common/adminService';

@Component({
  selector: 'app-view-text',
  templateUrl: './view-text.component.html',
  styleUrls: ['./view-text.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ViewTextComponent implements OnInit {
  id: string;
  getContentData: any;
  contentType: any;
  customClass = 'android-text';
  tempUSerAgent;
  isIosDevice: boolean = false;


  constructor(private _fuseConfigService: FuseConfigService,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private router: Router
  ) {
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit() {
    // if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
    var classNames = [];
    this.tempUSerAgent = navigator.userAgent;
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
      this.isIosDevice = true
      // this.customClass = 'ios-text';
    }

    if (navigator.userAgent.match(/android/i)) {
      this.isIosDevice = false
      // this.customClass = 'android-text';
    }

    this.adminService.getContentForUser(this.id).subscribe((content) => {
      this.getContentData = content['data'].content;
      this.contentType = content['data'].sContentType
      if (this.contentType !== 'sContent') {
        this.router.navigate(['errors/error-404']);
      }
    }, error => {
      this.router.navigate(['errors/error-404']);
    });
  }

}
