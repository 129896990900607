import {Injectable} from '@angular/core';
import { MatSnackBar} from '@angular/material';

@Injectable()
export class SnackBarService {
  constructor(public snackBar: MatSnackBar) {}
        snackbarMesssage(message,type){       
          this.snackBar.open(message, type, {
              duration: 3000,
              panelClass: ['info'],
              horizontalPosition:"right",
              verticalPosition:"top",
              });
    } 
}
