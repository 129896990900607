import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AdminService } from '../common/adminService';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SnackBarService } from 'app/main/common/snackbar.service';

@Component({
	selector: 'reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: [ './reset-password.component.scss' ],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	resetPasswordForm: FormGroup;
	password = '.{8,}';
	// Private
	private _unsubscribeAll: Subject<any>;
	tokenData: any;
	tokenVerify: boolean;
	loading :boolean = false;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private _formBuilder: FormBuilder,
		private adminService: AdminService,
		private snackBarService: SnackBarService,
		private router: Router,
		private route: ActivatedRoute,
		
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};

		// Set the private defaults
		this._unsubscribeAll = new Subject();

		this.route.params.subscribe((params) => {
			this.tokenData = params['token'];
		});
	}

	ngOnInit(): void {
		/** token verify api call */
		
		this.adminService.tokenVerify(this.tokenData).subscribe(
			(res) => {
				this.tokenVerify = true;
				if (res) {
				}
			},
			(error) => {
				this.tokenVerify = false;
				this.errorPage();
			}
		);

		/** Form  */
		this.resetPasswordForm = this._formBuilder.group({
			sNewPassword: [ '', Validators.compose([ Validators.required, Validators.pattern(this.password) ]) ],
			sNewRetypedPassword: [ '', [ Validators.required, confirmPasswordValidator ] ]
		});

		// Update the validity of the 'confirm_password' field
		// when the 'password' field changes
		this.resetPasswordForm.get('sNewPassword').valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this.resetPasswordForm.get('sNewRetypedPassword').updateValueAndValidity();
		});
	}

	/** Reset password Function */
	resetPassword() {
		this.loading = true;
		this.adminService
			.resetpassword(
				this.tokenData,
				this.resetPasswordForm.value.sNewPassword,
				this.resetPasswordForm.value.sNewRetypedPassword
			)
			.subscribe(
				(response) => {
					this.loading = false;
					let message = response.message;
					this.snackBarService.snackbarMesssage(message, 'Cancel');
					this.router.navigate([ '/' ]);
				},
				(error) => {
					this.loading = false;
					this.snackBarService.snackbarMesssage(error.message, 'Cancel');
					this.router.navigate([ '/' ]);
				}
			);
	}

	/** send flag in route */
	errorPage() {
		let navigationExtras: NavigationExtras = {
			queryParams: {
				isError: "true"
			},
			skipLocationChange: false
		};
		this.router.navigate([ '/mail-confirm' ], navigationExtras);
	}

	/**
     * On destroy
     */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	if (!control.parent || !control) {
		return null;
	}

	const password = control.parent.get('sNewPassword');
	const confirm_password = control.parent.get('sNewRetypedPassword');

	if (!password || !confirm_password) {
		return null;
	}

	if (confirm_password.value === '') {
		return null;
	}

	if (password.value === confirm_password.value) {
		return null;
	}

	return { passwordsNotMatching: true };
};
