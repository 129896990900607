import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MailConfirmComponent } from './mail-confirm.component';

const childRoutes: Routes = [
    {
        path: 'mail-confirm',
        component: MailConfirmComponent
    },
    {
        path: 'user/mail/verification/:tokenData',
        component: MailConfirmComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(childRoutes)],
    exports: [RouterModule],
})
export class MailConfirmRoutingModule { }
