import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		usertype: 'admin',
		type: 'item',
		icon: 'dashboard',
		url: '/dashboard'
	},
	{
		id: 'educational-management',
		title: 'Educational Module',
		usertype: 'admin',
		type: 'item',
		icon: 'school',
		url: '/educational/view'
	},
	{
		id: 'sub-admin',
		title: 'Sub-Admin Management',
		usertype: 'admin',
		type: 'item',
		icon: 'person',
		url: '/sub-admin/list'
	},
	{
		id: 'sponser-management',
		title: 'Sponsor Management',
		usertype: 'admin',
		type: 'item',
		icon: 'monetization_on',
		url: '/sponsor/list'
	},
	{
		id: 'categories-management',
		title: 'Categories Management',
		usertype: 'admin',
		type: 'item',
		icon: 'category',
		url: '/category/view'
	},
	{
		id: 'trial-tool',
		title: 'Trial Tool',
		usertype: 'admin',
		type: 'collapsable',
		icon: 'settings',
		children: [
			{
				id: 'countries',
				title: 'Countries',
				type: 'item',
				url: '/trial-tools/countries/list'
			},
			{
				id: 'city',
				title: 'City',
				type: 'item',
				url: '/trial-tools/city/list'
			},
			{
				id: 'disease',
				title: 'Disease',
				type: 'item',
				url: '/trial-tools/disease/list'
			},
			{
				id: 'study-status',
				title: 'Study Status',
				type: 'item',
				url: '/trial-tools/study_status/list'
			},
			{
				id: 'principal-investigator',
				title: 'Principal Investigator',
				type: 'item',
				url: '/trial-tools/principal_investigator/list'
			},
			{
				id: 'line-of-treatment',
				title: 'Line of Treatment',
				type: 'item',
				url: '/trial-tools/line_of_treatment/list'
			},
			{
				id: 'hospital',
				title: 'Hospital',
				type: 'item',
				url: '/trial-tools/hospital/list'
			},
			{
				id: 'criteria',
				title: 'Criteria',
				type: 'item',
				url: '/trial-tools/criteria/list'
			},
			{
				id: 'studies',
				title: 'Studies',
				type: 'item',
				url: '/trial-tools/studies/list'
			}
		]
	},
	{
		id: 'patients',
		title: 'Patients',
		usertype: 'admin',
		type: 'item',
		icon: 'person',
		url: '/patient/list'
	},
	{
		id: 'cms',
		title: 'About us',
		usertype: 'admin',
		type: 'item',
		icon: 'pages',
		url: '/cms/list'
	},
	{
		id: 'notification',
		title: 'Send Push Notification',
		usertype: 'admin',
		type: 'item',
		icon: 'notifications_none',
		url: '/notification'
	},
	{
		id: 'dashboard',
		title: 'dashboard',
		usertype: 'investigator',
		type: 'item',
		icon: 'dashboard',
		url: '/dashboard'
	},
	{
		id: 'patients',
		title: 'patients',
		usertype: 'investigator',
		type: 'item',
		icon: 'dashboard',
		url: '/patients/list'
	},
	{
		id: 'search-criteria',
		title: 'Search criteria',
		usertype: 'admin',
		type: 'item',
		icon: 'dashboard',
		url: '/search-criteria'
	}
];
