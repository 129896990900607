import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SearchComponent } from 'app/main/admin/search/search.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
	MatButtonModule,
	MatFormFieldModule,
	MatIconModule,
	MatInputModule,
	MatSelectModule,
	MatDialogModule,
	MatProgressSpinnerModule,
  MatRadioModule
} from '@angular/material';

import { CmsListComponent } from 'app/main/admin/cms/cms-list/cms-list.component';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { CKEditorModule } from 'ng2-ckeditor';
import {  CommonModule } from '@angular/common';
import { FuseConfirmDialogModule } from '@fuse/components';


@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    NgxDatatableModule,
    FuseSharedModule,
    CKEditorModule,
    FuseConfirmDialogModule,
  ]
})
export class SearchModule { }
