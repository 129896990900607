import { Component, ViewEncapsulation, OnInit, ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { AdminService } from 'app/main/common/adminService';
import { SnackBarService } from 'app/main/common/snackbar.service';
import { FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { environment } from 'environments/environment';
import { FuseConfigService } from '@fuse/services/config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';



@Component({
	selector: 'app-add-investigatorbylink',
	templateUrl: './add-investigatorbylink.component.html',
	styleUrls: ['./add-investigatorbylink.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})

export class AddInvestigatorbylinkComponent implements OnInit {
	profileForm: any;
	requireImage: string;
	formatInvailid: string;
	sizeInvailid: string;
	profilePicUrl: any;
	inputElActive: any;
	response: any;
	password = '.{8,}';
	phone = '^[0-9]{6,15}$';
	loading: boolean = false;
	loadingpage: boolean = true;
	countryData = [];
	cityData: any[] = [];
	hospitalList: any[] = [];
	data: string;
	countrySearch: string;
	citySearch: string;
	welcomeText: any;
	/**
		* Constructor
		*
		* @param {FuseConfigService} _fuseConfigService
		* @param {FormBuilder} _formBuilder
		*/
	constructor(
		private adminService: AdminService,
		private router: Router,
		private snackBarService: SnackBarService,
		private _formBuilder: FormBuilder,
		private el: ElementRef,
		private _fuseConfigService: FuseConfigService,
		public _sanitizer: DomSanitizer,
		private dialog: MatDialog,
	) {
		
		const urlSplit = this.router.url.split('/');
		this.data = urlSplit[urlSplit.length - 1];
		

		// Configure the layout
		this._fuseConfigService.config = {
			colorTheme: 'theme-default',
			layout: {
				style: 'vertical-layout-1',
				width: 'fullwidth',
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
	}

	ngOnInit() {

		// get country data
		this.countrySearch = '';
		let categories = this.adminService.selectCountryNoAuth(this.countrySearch).toPromise();
		categories.then((response) => {
			this.countryData = response.data.data;
		}, error => {
			if (error && typeof error == "object" && error.hasOwnProperty('error')) {
				this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
			} else if (typeof error == "string") {
				this.snackBarService.snackbarMesssage(error, 'Cancel');
			}
		});


		this.loadingpage = true;
		this.adminService.getinvestigatorbystudy(this.data).subscribe((data: any) => {
			
			this.response = data.data;
		})

		this.loadingpage = false;
		this.profileForm = this._formBuilder.group({
			sName: ['', [Validators.required]],
			sEmail: ['', Validators.required],
			sPhoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.phone)])],
			iCountryId: ['', Validators.required],
			iCityId: ['', Validators.required],
			iHospitalId: ['', Validators.required],
			sPassword: ['', Validators.compose([Validators.required, Validators.pattern(this.password)])],
			sConfirmPassword: ['', confirmPasswordValidator]
		});
		this.profileForm.get('sPassword').valueChanges
			.subscribe(() => {
				this.profileForm.get('sConfirmPassword').updateValueAndValidity();
			});

		this.welcomeText = "Welcome to the HematologyApp trial Tool. Please register yourself for this study as a (local) PI. In compliance with the GDPR, we must ask your permission to save and use your data in the Trial Tool. By registering your details below you give us this permission. Your data will only be used so that a patient referrer can contact you to discuss participation in this study. We will not use your data for any other purpose. For our privacy policy, please refer to our website <a href='https://appsforcareandscience.eu/privacy/' target='_blank'>https://appsforcareandscience.eu/privacy/</a>";
		this.welcomeText = this._sanitizer.bypassSecurityTrustHtml(this.welcomeText);
	}

	// Get Cities From Country Id
	getCityData(e, i) {
		this.citySearch = '';
		let categories = this.adminService.selectCityNoAuth(e, this.countrySearch).toPromise();
		categories.then((response) => {
			

			// this.snackBarService.snackbarMesssage(response['message'], 'Cancel');
			this.cityData = response.data.data;
		}, error => {
			if (error && typeof error == "object" && error.hasOwnProperty('error')) {
				this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
			} else if (typeof error == "string") {
				this.snackBarService.snackbarMesssage(error, 'Cancel');
			}
		});
	}


	// Get Hospitals From City Id
	getHospitalWithCityid(e) {
		this.adminService.selectHospitalWithCityidNoAuth(e).subscribe(res => {
			this.hospitalList = res.data;
			res.data.forEach(element => {
				/** _id is index */
				/** index 1 =  'hospital._id' */
				// this.hospitalListWithPI[element._id] = element;
			});
		}, error => {
			// this.snackBarService.snackbarMesssage(error.message, 'Cancel');
			if (error && typeof error == "object" && error.hasOwnProperty('error')) {
				this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
			} else if (typeof error == "string") {
				this.snackBarService.snackbarMesssage(error, 'Cancel');
			}
		});
	}



	//image upload
	readUrl(event) {
		if (event) {
			this.requireImage = '';
			this.formatInvailid = '';
			this.sizeInvailid = '';

			if (event.target.files && event.target.files[0]) {
				if (event.target.files && event.target.files[0].size < 5017241) {
					var fileExt = event.target.files[0].name.split('.').pop();
					var allowedExtensions = environment.allowedImages;
					if (allowedExtensions.indexOf(fileExt) > -1) {
						var reader = new FileReader();
						reader.onload = (event) => {
							this.profilePicUrl = event.target['result'];
						};
						reader.readAsDataURL(event.target.files[0]);
						this.inputElActive = HTMLInputElement = this.el.nativeElement
							.querySelector('#file')
							.files.item(0);

						//profile picture api call
						let formData = new FormData();
						formData.append('sProfilePicture', this.inputElActive);
						this.adminService.updateprofilepic(formData).subscribe(
							(user) => {
								this.snackBarService.snackbarMesssage(user['message'], 'Cancel');
								this.adminService.getprofiledata().subscribe((data) => { });
							},
							(error) => {
								this.snackBarService.snackbarMesssage(error.error['message'], 'Cancel');
							}
						);
					} else {
						this.inputElActive = this.profilePicUrl;
						this.snackBarService.snackbarMesssage(
							'Profile picture must be in PNG,JPG or JPEG format',
							'Cancel'
						);
					}
				} else {
					this.snackBarService.snackbarMesssage('Image picture size must be less than 1 MB', 'Cancel');
				}
			}
		}
	}

	editProfile() {
		var data = {
			'iCountryId': this.profileForm.value.iCountryId,
			'iCityId': this.profileForm.value.iCityId,
			'iHospitalId': this.profileForm.value.iHospitalId,
			'iStudyId': this.data,
			'sName': this.profileForm.value.sName,
			'sEmail': this.profileForm.value.sEmail,
			'sPhoneNumber': this.profileForm.value.sPhoneNumber,
			'sPassword': this.profileForm.value.sPassword

		}
		
		this.loading = true;
		
		this.adminService.investigatorformsubmit(data).subscribe(
			(user) => {
				this.loading = false;
				// this.snackBarService.snackbarMesssage(user['message'], 'Cancel');
				// this.router.navigate(['/investigator']);
				const dialogRef = this.dialog.open(DialogMessageComponent,{
					width: '700px'
				});
			},
			(error) => {
				this.loading = false;
				this.snackBarService.snackbarMesssage(error.error.message, 'Cancel');
			}
		);
	}
}



/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

	if (!control.parent || !control) {
		return null;
	}

	const password = control.parent.get('sPassword');
	const confirm_password = control.parent.get('sConfirmPassword');

	if (!password || !confirm_password) {
		return null;
	}

	if (confirm_password.value === '') {
		return null;
	}

	if (password.value === confirm_password.value) {
		return null;
	}

	return { 'passwordsNotMatching': true };
};

