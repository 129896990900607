// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// export const environment = {
//     production: false,
//     hmr: false,
//     projectName: 'Hematology',
//     profileUrl: 'http://192.168.11.118:3001/uploads/profilePictures/',
//     sponserUrl: 'http://192.168.11.118:3001/uploads/sponsorImage/',
//     sponsorImageResize: 'http://192.168.11.118:3001/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/sponsorImage/',
//     countryImageResize: 'http://192.168.11.118:3001/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/countryFlagImage/',
//     sCategoryImageUrl: 'http://192.168.11.118:3001/uploads/categoryImages/',
//     sPatientImageUrl: 'http://192.168.11.118:3001/uploads/patientDetailImages/',
//     pdfImageUrl: 'http://192.168.11.118:3001/uploads/categoryPdf/',
//     studiesImageUrl: 'http://192.168.11.118:3001/uploads/studyImages/',
//     countryImageUrl: 'http://192.168.11.118:3001/uploads/countryFlagImage/',
//     apiUrl: 'http://192.168.11.118:3001',
//     version: '/api/v1/',
//     allowedImages: ['jpg', 'jpeg', 'png'],
//     allowedFile: ['xlsx'],
//     accordionViewUrl: 'http://192.168.11.128:4200/accordion/view/',
//     textViewUrl: 'http://192.168.11.128:4200/text/view/',
//     pdfViewUrl: 'http://192.168.11.128:4200/pdf/view/',
//     calculatorViewUrl: 'http://192.168.11.128:4200/calculator/view/',
//     guidelineViewUrl: 'http://192.168.11.128:4200/guideline/view/',
//     graphicalViewUrl: 'http://192.168.11.128:4200/graphical/view/',
// };

export const environment = {
    production: true,
    hmr: false,
    projectName: 'Hematology',
    // sponsorImageResize: 'http://34.225.80.141:3001/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/sponsorImage/',
    // countryImageResize: 'http://34.225.80.141:3001/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/countryFlagImage/',
    // profileUrl: 'http://34.225.80.141:3001/uploads/profilePictures/',
    // sponserUrl: 'http://34.225.80.141:3001/uploads/sponsorImage/',
    // sCategoryImageUrl: 'http://34.225.80.141:3001/uploads/categoryImages/',
    // sPatientImageUrl: 'http://34.225.80.141:3001/uploads/patientDetailImages/',
    // pdfImageUrl: 'http://34.225.80.141:3001/uploads/categoryPdf/',
    // studiesImageUrl: 'http://34.225.80.141:3001/uploads/studyImages/',
    // countryImageUrl: 'http://34.225.80.141:3001/uploads/countryFlagImage/',
    // apiUrl: 'http://34.225.80.141:3001',
    // apiUrl: 'https://api.hematrialtool.com',
    // apiUrl:'http://3.7.98.19:3001',

    // Live url
    apiUrl: 'https://api.hematrialtool.com',
    sponsorImageResize: 'https://api.hematrialtool.com/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/sponsorImage/',
    sBannerImages:'http://api.hematrialtool.com/api/v1/admin/imageresize/sharpresize?src=public/uploads/educationalMedia/',
    countryImageResize: 'https://api.hematrialtool.com/api/v1/admin/imageresize/sharpresize?w=40&h=40&q=100&src=public/uploads/countryFlagImage/',
    profileUrl: 'https://api.hematrialtool.com/uploads/profilePictures/',
    sponserUrl: 'https://api.hematrialtool.com/uploads/sponsorImage/',
    sCategoryImageUrl: 'https://api.hematrialtool.com/uploads/categoryImages/',
    sPatientImageUrl: 'https://api.hematrialtool.com/uploads/patientDetailImages/',
    pdfImageUrl: 'https://api.hematrialtool.com/uploads/categoryPdf/',
    studiesImageUrl: 'https://api.hematrialtool.com/uploads/studyImages/',
    countryImageUrl: 'https://api.hematrialtool.com/uploads/countryFlagImage/',

    version: '/api/v1/',
    allowedImages: ['jpg', 'jpeg', 'png'],
    allowedFile: ['xlsx'],
    allowedVideo: ['mkv', 'webm', 'mp4'],
    allowedAudio: ['mp3'],
    accordionViewUrl: 'http://34.225.80.141:6500/accordion/view/',
    textViewUrl: 'http://34.225.80.141:6500/text/view/',
    pdfViewUrl: 'http://34.225.80.141:6500/pdf/view/',
    calculatorViewUrl: 'http://34.225.80.141:6500/calculator/view/',
    guidelineViewUrl: 'http://34.225.80.141:6500/guideline/view/',
    graphicalViewUrl: 'http://34.225.80.141:6500/graphical/view/',
    sAudioFile:'https://api.hematrialtool.com/uploads/educationalMedia/',
    sVideoFile:'https://api.hematrialtool.com/uploads/educationalMedia/',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
