import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../common/adminService';
import { environment } from 'environments/environment';

@Component({
	selector: 'mail-confirm',
	templateUrl: './mail-confirm.component.html',
	styleUrls: ['./mail-confirm.component.scss'],
	encapsulation: ViewEncapsulation.None,
	animations: fuseAnimations
})
export class MailConfirmComponent implements OnInit {
	isError: string = 'false';
	Femail: string;
	token: string;
	subAdminTokenVerify: boolean;
	projectName: any;

	constructor(
		private _fuseConfigService: FuseConfigService,
		private route: ActivatedRoute,
		private adminService: AdminService
	) {
		// Configure the layout
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					hidden: true
				},
				toolbar: {
					hidden: true
				},
				footer: {
					hidden: true
				},
				sidepanel: {
					hidden: true
				}
			}
		};
		this.route.params.subscribe((params) => {
			this.token = params.tokenData;
		});

		this.route.queryParamMap.subscribe((params) => {
			if (params.get('isError')) {
				this.isError = params.get('isError');
			}
			if (params.get('Femail')) {
				this.Femail = params.get('Femail');
			}
		});
	}

	ngOnInit(): void {
		this.projectName = environment.projectName
		if (this.token) {
			this.adminService.subAdminTokenVerify(this.token).subscribe(
				(res) => {
					this.subAdminTokenVerify = true;
					if (res) {
					}
				},
				(error) => {
					this.subAdminTokenVerify = false;
				}
			);
		}
	}
}
