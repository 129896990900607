import { Component, OnInit, Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html'
})
export class DialogMessageComponent {

  link = document.location.origin + '/investigator'
  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogRef: MatDialogRef<DialogMessageComponent>) {
   
    this.dialogRef.updateSize('300vw','300vw')
    console.log(this.link);
    
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
